import React from 'react';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PageHeading from '../../components/PageHeading/PageHeading';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const CareerDay = () => {
  return (
    <Layout>
      <Helmet
        title="VEYM | Career Day"
        meta={[
          {
            name: 'description',
            content: 'VEYM Career Day',
          },
        ]}
      />
      <PageHeading>Career Day</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/news">News</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Career Day</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <Container>
        <br />
        <h4>Day 1 - February 3, 2021 @ 9:00 PM EDT:</h4>
        Main room:{' '}
        <a
          href="https://url.veym.net/ServiceMissionaryCareerFairDay1"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://url.veym.net/ServiceMissionaryCareerFairDay1
        </a>
        <br />
        <br />
        <h5>Day 1 Breakout Rooms</h5>
        <ul>
          <li>
            Armed Services:{' '}
            <a
              href="https://url.veym.net/CareerDay-ArmedServices"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-ArmedServices
            </a>
          </li>
          <li>
            Diplomacy:{' '}
            <a
              href="https://url.veym.net/CareerDay-Diplomacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-Diplomacy
            </a>
          </li>
          <li>
            Law Enforcement:{' '}
            <a
              href="https://url.veym.net/CareerDay-LawEnforcement"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-LawEnforcement
            </a>
          </li>
          <li>
            Immigration Law:{' '}
            <a
              href="https://url.veym.net/CareerDay-ImmigrationLaw"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-ImmigrationLaw
            </a>
          </li>
          <li>
            Religious:{' '}
            <a
              href="https://url.veym.net/CareerDay-Religious"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-Religious
            </a>
          </li>
        </ul>
        <br />
        <br />
        <h4>Day 2: February 4, 2021 @ 9:00 PM EDT:</h4>
        Main room:{' '}
        <a
          href="https://url.veym.net/ServiceMissionaryCareerFairDay2"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://url.veym.net/ServiceMissionaryCareerFairDay2
        </a>
        <br />
        <br />
        <h5>Day 2 Breakout Rooms</h5>
        <ul>
          <li>
            Education:{' '}
            <a
              href="https://url.veym.net/CareerDay-Education"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-Education
            </a>
          </li>
          <li>
            Advocacy:{' '}
            <a
              href="https://url.veym.net/CareerDay-Advocacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-Advocacy
            </a>
          </li>
          <li>
            Social Work:{' '}
            <a
              href="https://url.veym.net/CareerDay-SocialWork"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-SocialWork
            </a>
          </li>
          <li>
            Higher Education:{' '}
            <a
              href="https://url.veym.net/CareerDay-HigherEducation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-HigherEducation
            </a>
          </li>
          <li>
            Religious-2:{' '}
            <a
              href="https://url.veym.net/CareerDay-Religious-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://url.veym.net/CareerDay-Religious-2
            </a>
          </li>
        </ul>
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  );
};

export default CareerDay;
